import styled from 'styled-components'
import { theme } from '../../style/theme'

interface Props {
  text: string
}
export const Button = (props: Props) => {
  return <ButtonContainer>{props.text}</ButtonContainer>
}

const ButtonContainer = styled.button`
  background-color: ${theme.color.cta};
  color: ${theme.text.title};

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: none;
  border-radius: 20px;

  margin: 1rem;
  padding: 10px 20px;

  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`
