import { useCheckMobileScreen } from 'hooks/useCheckMobileScreen'

import { Legend } from '../../../../typographie/Legend'
import { IGite } from '../../../../models/Gites'
import { H4 } from '../../../../typographie/H4'
import { P } from '../../../../typographie/P'

import styled from 'styled-components'
import { useCheckTabletScreen } from 'hooks/useCheckTabletScreen'

interface Props {
  gite: IGite
}

export const GiteDescription = ({ gite }: Props) => {
  const isMobile = useCheckMobileScreen()
  const isTablet = useCheckTabletScreen()

  const isDesktop = !isTablet && !isMobile

  return (
    <GiteDescriptionContainer>
      <Categorie>
        <H4>Capacité</H4>
      </Categorie>
      <Description>{gite.capacity} personnes</Description>
      <Categorie>
        <H4>Superficie</H4>
      </Categorie>
      <Description>{gite.superficy} m²</Description>
      <Categorie>
        <H4>Couchage</H4>
      </Categorie>
      <Description>{gite.beds}</Description>
      <Categorie>
        <H4>Equipements</H4>
      </Categorie>
      <IconContainer>
        {gite.equipements.map((equipement, index) => (
          <Figure key={index}>
            <Icon src={equipement.icon} />
            <Figcaption isDesktop={isDesktop}>{equipement.name}</Figcaption>
          </Figure>
        ))}
      </IconContainer>
      <Legend>{gite.bonus}</Legend>
    </GiteDescriptionContainer>
  )
}

const GiteDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 10%;

  text-align: center;
`

const Categorie = styled.div`
  background-color: white;
`

const Description = styled(P)`
  text-align: center;
  color: black;
  font-size: 1rem;
  margin: 20px 5px;
`
const IconContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  margin: 10px 0;

  padding: 10px 50px;
`

const Figure = styled.figure`
  margin: 5px;

  &:hover {
    figcaption {
      visibility: visible;
    }
  }
`

const Figcaption = styled.figcaption<{ isDesktop: boolean }>`
  width: 100%;

  font-size: 0.7rem;
  text-align: center;

  ${({ isDesktop }) => isDesktop && 'visibility: hidden'}
`

const Icon = styled.img`
  width: 4rem;
  height: 4rem;
`
