import { useCheckMobileScreen } from '../../../hooks/useCheckMobileScreen'
import { Button } from '../../../components/common/Button'
import { MOBILE_BREAKPOINT } from 'const/breakpoint'
import { modalService } from 'services/modal'
import { H2 } from '../../../typographie/H2'
import { P } from '../../../typographie/P'
import { Link } from 'react-router-dom'

import Cour from '../../../assets/images/cedres/outdoor1.jpeg'
import Alley from '../../../assets/images/common/alley.jpeg'
import Tree from '../../../assets/images/common/view1.jpeg'
import BBQ from '../../../assets/images/pins/bbq.jpeg'

import styled from 'styled-components'

export const Description = (): JSX.Element => {
  const isMobile = useCheckMobileScreen()

  const enlargePicture = (picture: string): void => {
    modalService.openModal()
    modalService.loadPicture(picture)
  }

  return (
    <DescriptionContainer id='description'>
      <TextContainer>
        <H2>Bienvenue à la Casa de Jupas</H2>
        <P>
          Entre mer et montagne, Pascaline et Julien ont le plaisir de vous accueillir à la Casa de
          Jupas, grande propriété composée de gîtes climatisés avec terrasses privatives. Située à
          35 minutes de Montpellier et des plages, 15 minutes de Nîmes et Sommières, sa localisation
          vous permettra de découvrir une magnifique région pleine d’activités variées : randonnées,
          plages, piscine, vestiges romains, ville médiévale, loisirs équestres… Venez passer un
          séjour de détente, dans un esprit nature et cocooning.
        </P>
      </TextContainer>
      <ButtonContainer to='gites'>
        <Button text='Découvrez nos gîtes' />
      </ButtonContainer>
      <ImageContainer>
        {!isMobile && <Left src={Alley} onClick={() => enlargePicture(Alley)} />}
        <UpperRight src={BBQ} onClick={() => enlargePicture(BBQ)} />
        <Down1 src={Cour} onClick={() => enlargePicture(Cour)} />
        <Down2 src={Tree} onClick={() => enlargePicture(Tree)} />
      </ImageContainer>
    </DescriptionContainer>
  )
}

const DescriptionContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`

const TextContainer = styled.div`
  width: 70%;
  text-align: center;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`

const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: repeat(2, 10rem);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`

const ButtonContainer = styled(Link)`
  margin: 30px;
`

const Image = styled.img`
  cursor: pointer;
`

const Left = styled(Image)`
  grid-area: 1 / 1 / 3 / 3;
`

const UpperRight = styled(Image)`
  grid-area: 1 / 3 / 2 / 5;
`

const Down1 = styled(Image)`
  grid-area: 2 / 3 / 3 / 4;
`

const Down2 = styled(Image)`
  filter: saturate(120%) contrast(140%);

  grid-area: 2 / 4 / 3 / 5;
`
