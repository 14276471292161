import styled from 'styled-components'
import PoolPicture from '../../../assets/images/common/pool1.jpeg'
import AlleyPicture from '../../../assets/images/common/alley.jpeg'

import { H1 } from '../../../typographie/H1'
import { ScrollDownArrow } from '../../../components/common/ScrollDownArrow'
import { useCheckMobileScreen } from '../../../hooks/useCheckMobileScreen'

export const Hero = (): JSX.Element => {
  const isMobile = useCheckMobileScreen()

  return (
    <HeroContainer isMobile={isMobile}>
      <H1>La Casa de Jupas</H1>
      <ScrollDownArrow page={'description'} />
    </HeroContainer>
  )
}
const HeroContainer = styled.section<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: ${({ isMobile }) => (isMobile ? `url(${AlleyPicture})` : `url(${PoolPicture})`)};
  background-size: cover;
  background-attachment: fixed;

  filter: saturate(120%);

  H1 {
    padding-bottom: 150px;
  }
`
