import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from '../../style/theme'

interface Props {
  isTransparentNavBar: boolean
  path: string
  name: string
}

export const NavBarLink = ({ isTransparentNavBar, path, name }: Props): JSX.Element => {
  return (
    <NavBarLinkContainer isTransparentNavBar={isTransparentNavBar}>
      <Link to={path}>{name}</Link>
    </NavBarLinkContainer>
  )
}

const NavBarLinkContainer = styled.div<{ isTransparentNavBar: boolean }>`
  font-size: 1.6rem;

  margin: 15px 30px;

  text-shadow: ${theme.textShadow.main};
  color: ${({ isTransparentNavBar }) => (isTransparentNavBar ? 'black' : 'white')};
  transition: all 400ms ease;

  &:hover {
    cursor: pointer;
    color: ${({ isTransparentNavBar }) =>
      isTransparentNavBar ? theme.color.main : theme.color.secondary};
    text-decoration: underline;
  }
`
