import { theme } from 'style/theme'

import styled from 'styled-components'

export const DisplayedPicture = styled.img<{ isMobile: boolean }>`
  max-width: ${({ isMobile }) => (isMobile ? '60vw' : '80vw')};
  max-height: 90vh;

  width: auto;
  height: auto;

  box-shadow: ${theme.boxShadow.secondary};

  margin: auto;
`
