import styled from 'styled-components'
import { theme } from '../../style/theme'

export const Cross = styled.div`
  z-index: 11;
  cursor: pointer;

  position: absolute;
  right: 20px;
  top: 20px;

  width: 42px;
  height: 42px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }

  &:before,
  &:after {
    position: absolute;
    right: 15px;
    content: ' ';
    height: 43px;
    width: 2px;
    background-color: ${theme.color.secondary};
  }

  &:before {
    transform: rotate(-45deg);
  }

  &:after {
    transform: rotate(45deg);
  }
`
