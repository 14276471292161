import styled from 'styled-components'

export const Table = styled.table`
  width: 70vw;
`

export const Header = styled.thead``

export const Body = styled.tbody``

export const Line = styled.tr`
  background-color: white;
  text-align: center;
`

export const HeaderCell = styled.th`
  padding: 20px;
`

export const Cell = styled.td`
  padding: 20px;
`
