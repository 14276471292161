import { createGlobalStyle } from 'styled-components'
import { theme } from './theme'

import Merienda from '../assets/fonts/MeriendaOne-Regular.ttf'
import Montserrat from '../assets/fonts/Montserrat.ttf'
import MontserratItalic from '../assets/fonts/Montserrat-Italic.ttf'
import { MOBILE_BREAKPOINT } from '../const/breakpoint'

export const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'Merienda';
  src: url(${Merienda}) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  src: url(${Montserrat}) format('truetype');
}

@font-face {
  font-family: 'MontserratItalic';
  src: url(${MontserratItalic}) format('truetype');
}

body {
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  
  font-size: 100%;
  min-height: -webkit-fill-available;
  font-family: 'Montserrat';
}

section {
max-width: 100vw;
min-height: 100vh;

background-color: ${theme.color.main};

box-sizing: border-box;
padding: 100px 12% 5% 12%;
}

a {
  text-decoration: none;
  color: inherit;
}

h1 {
  color: white;
}

h2, h3, h4, h5, h6 {
  color: ${theme.text.title};
}

h1, h2, h3 {
  font-family: 'Merienda';
}

p {
  color: ${theme.text.main};
}

li {
  list-style-type: none;
}

img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
  html {
    font-size: 80%;
  }
}
`
