import styled from 'styled-components'
import { theme } from '../style/theme'

export const H4 = styled.h4`
  margin: 0.5rem 0;
  font-size: 1.2rem;

  text-align: center;

  color: ${theme.text.main};
`
