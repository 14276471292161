import { Link } from 'react-router-dom'
import { theme } from '../../style/theme'

import { useNavBarLocation } from '../../hooks/useNavBarLocation'
import { NavBarRoutes } from '../../models/Routes'
import { NavBarLink } from './NavBarLink'

import BlueNOBG from '../../assets/logos/blue-nobg.png'
import WhiteNOBG from '../../assets/logos/white-nobg.png'

import styled from 'styled-components'

export const NavBar = (): JSX.Element => {
  const isTransparentNavBar = useNavBarLocation()

  const GoToUpperPage = () => window.scrollTo(0, 0)

  return (
    <NavBarContainer isTransparentNavBar={isTransparentNavBar}>
      <LogoContainer to='/' onClick={GoToUpperPage}>
        <Logo src={isTransparentNavBar ? BlueNOBG : WhiteNOBG} />
      </LogoContainer>
      <LinkContainer>
        {NavBarRoutes.map((link, index) => (
          <NavBarLink
            key={index}
            isTransparentNavBar={isTransparentNavBar}
            path={link.path}
            name={link.name}
          />
        ))}
      </LinkContainer>
    </NavBarContainer>
  )
}

const NavBarContainer = styled.div<{ isTransparentNavBar: boolean }>`
  display: flex;
  position: fixed;
  align-items: center;
  height: 100px;
  width: 100vw;
  z-index: 2;
  background: linear-gradient(${theme.color.main} 40%, rgba(0, 0, 0, 0));

  ${({ isTransparentNavBar }) => isTransparentNavBar && 'background: none'};
`

const LogoContainer = styled(Link)`
  height: 70%;
  margin-left: 8%;
`

const Logo = styled.img`
  width: auto;
`

const LinkContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;

  list-style-type: none;

  margin-right: 15%;
`
