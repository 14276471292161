import { useEffect, useState } from 'react'

import { picturesCarouselService } from '../../services/picturesCarousel'
import { useCheckMobileScreen } from '../../hooks/useCheckMobileScreen'
import { ModalContainer } from 'components/modal/common/ModalContainer'
import { modalService } from '../../services/modal'
import { gitesCarouselService } from '../../services/gitesCarousel'
import { BlurredBackground } from './common/BlurredBackground'
import { DisplayedPicture } from './common/DisplayedPicture'
import { NavigationArrow } from '../common/NavigationArrow'
import { IGite } from '../../models/Gites'
import { Cross } from '../common/Cross'

import ArrowRight from '../../assets/icons/circle-arrow-right.png'
import ArrowLeft from '../../assets/icons/circle-arrow-left.png'

export const CarouselModal = (): JSX.Element => {
  const [isShown, setIsShown] = useState<boolean>(false)
  const [picture, setPicture] = useState<string>('')
  const [selectedGite, setSelectedGite] = useState<IGite>()

  const isMobile = useCheckMobileScreen()

  // Define if displayed gite is first or last to hide arrows if it's the case
  const photosListLength = selectedGite?.photos.length && selectedGite.photos.length - 1
  const isFirstGite = selectedGite?.photos.indexOf(picture) === 0
  const isLastGite = selectedGite?.photos.indexOf(picture) === photosListLength

  // Load selected Picture, selected Gite & isModalShown boolean
  useEffect(() => {
    const isShownSubscriber = modalService._isModalShown.subscribe((res) => setIsShown(res))
    const pictureSubscriber = modalService._picture.subscribe((res) => setPicture(res))
    const giteSubscriber = gitesCarouselService._selectedGite.subscribe((res) =>
      setSelectedGite(res),
    )

    // Unsubscribe to rxjs observables
    return () => {
      isShownSubscriber.unsubscribe()
      pictureSubscriber.unsubscribe()
      giteSubscriber.unsubscribe()
    }
  }, [])

  return (
    <ModalContainer isShown={isShown}>
      <BlurredBackground>
        <NavigationArrow
          src={ArrowLeft}
          style={isFirstGite ? { visibility: 'hidden' } : {}}
          onClick={picturesCarouselService.previousPicture}
        />
        <>
          <DisplayedPicture isMobile={isMobile} src={picture} />
          <Cross onClick={modalService.closeModal} />
        </>
        <NavigationArrow
          src={ArrowRight}
          style={isLastGite ? { visibility: 'hidden' } : {}}
          onClick={picturesCarouselService.nextPicture}
        />
      </BlurredBackground>
    </ModalContainer>
  )
}
