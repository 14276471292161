import { useEffect, useState } from 'react'

import { useCheckMobileScreen } from 'hooks/useCheckMobileScreen'

import { BlurredBackground } from './common/BlurredBackground'
import { DisplayedPicture } from './common/DisplayedPicture'
import { ModalContainer } from './common/ModalContainer'
import { Cross } from 'components/common/Cross'
import { modalService } from 'services/modal'

export const PictureModal = (): JSX.Element => {
  const [isShown, setIsShown] = useState<boolean>(false)
  const [picture, setPicture] = useState<string>('')

  const isMobile = useCheckMobileScreen()

  // Load selected Picture & isModalShown boolean
  useEffect(() => {
    const isShownSubscriber = modalService._isModalShown.subscribe((res) => setIsShown(res))
    const pictureSubscriber = modalService._picture.subscribe((res) => setPicture(res))

    // Unsubscribe to rxjs observables
    return () => {
      isShownSubscriber.unsubscribe()
      pictureSubscriber.unsubscribe()
    }
  }, [])

  return (
    <ModalContainer isShown={isShown}>
      <BlurredBackground>
        <DisplayedPicture isMobile={isMobile} src={picture} />
        <Cross onClick={modalService.closeModal} />
      </BlurredBackground>
    </ModalContainer>
  )
}
