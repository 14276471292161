import { BehaviorSubject } from 'rxjs'
import { gitesCarouselService } from './gitesCarousel'

const _picturesList$ = new BehaviorSubject<string[]>([])
const _selectedPicture$ = new BehaviorSubject<string>('')
const _pictureIndex$ = new BehaviorSubject<number>(0)

export const picturesCarouselService = {
  _selectedPicture: _selectedPicture$.asObservable(),
  _pictureIndex: _pictureIndex$.asObservable(),
  _picturesList: _picturesList$.asObservable(),

  loadPictures() {
    gitesCarouselService._selectedGite.subscribe((value) => _picturesList$.next(value.photos))

    const pictureList = _picturesList$.value
    const pictureIndex = _pictureIndex$.value

    _selectedPicture$.next(pictureList[pictureIndex])
  },

  selectPicture(index: number) {
    _pictureIndex$.next(index)
    const pictureList = _picturesList$.value

    _selectedPicture$.next(pictureList[index])
  },

  previousPicture() {
    _pictureIndex$.next(_pictureIndex$.value - 1)

    const pictureList = _picturesList$.value
    const pictureIndex = _pictureIndex$.value

    _selectedPicture$.next(pictureList[pictureIndex])
  },

  nextPicture() {
    _pictureIndex$.next(_pictureIndex$.value + 1)

    const pictureList = _picturesList$.value
    const pictureIndex = _pictureIndex$.value

    _selectedPicture$.next(pictureList[pictureIndex])
  },

  resetIndex() {
    _pictureIndex$.next(0)
  },
}
