import { keyframes } from 'styled-components'

export const REM_SIZE = 16

export const ArrowAnimation = keyframes`
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
`
export const StopAnimation = keyframes``
