import styled from 'styled-components'

export const ModalContainer = styled.div<{ isShown: boolean | undefined }>`
  z-index: 10;

  visibility: ${({ isShown }) => (isShown ? 'visible' : 'hidden')};
  opacity: ${({ isShown }) => (isShown ? 1 : 0)};

  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;

  width: 100vw;
  height: 100vh;

  backdrop-filter: blur(6px);

  transition: all 0.3s ease-in-out;
`
