import { Layout } from '../../layout/Layout'
import { Tarifs } from './tarifs/Tarifs'

export const Pricing = (): JSX.Element => {
  return (
    <Layout>
      <Tarifs />
    </Layout>
  )
}
