import styled from 'styled-components'
import { MobileNavBar } from '../components/navbar/MobileNavBar'
import { NavBar } from '../components/navbar/NavBar'
import { useCheckMobileScreen } from '../hooks/useCheckMobileScreen'

export const Layout = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  const isMobile = useCheckMobileScreen()

  return (
    <LayoutContainer>
      {isMobile ? <MobileNavBar /> : <NavBar />}
      <Content>{children}</Content>
    </LayoutContainer>
  )
}
const LayoutContainer = styled('div')`
  display: flex;
  flex-flow: column;
`
const Content = styled('div')`
  display: flex;
  flex-flow: column;
`
