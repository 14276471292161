import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Contact } from './views/contact/Contact'
import { Gites } from './views/gites/Gites'
import { Home } from './views/home/Home'
import { Pricing } from './views/pricing/Pricing'

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='gites' element={<Gites />} />
        <Route path='tarifs' element={<Pricing />} />
        <Route path='contact' element={<Contact />} />
      </Routes>
    </BrowserRouter>
  )
}
