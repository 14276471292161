import { Gîte } from './gite/Gite'
import { Layout } from '../../layout/Layout'

export const Gites = () => {
  return (
    <Layout>
      <Gîte />
    </Layout>
  )
}
