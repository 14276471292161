import { NavLink } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { NavBarRoutes } from '../../models/Routes'
import { theme } from '../../style/theme'

export const MobileMenu = (): JSX.Element => {
  return (
    <MobileMenuContainer>
      <List>
        {NavBarRoutes.map((route, index) => (
          <MenuLink
            key={index}
            to={route.path}
            className={(isActive) => (isActive ? 'active' : 'inactive')}
          >
            {route.name}
          </MenuLink>
        ))}
      </List>
    </MobileMenuContainer>
  )
}

const slideIn = keyframes`
from {
  margin-top: -100vh; 
}
to {
  margin-top: 0;
}
`

const MobileMenuContainer = styled.div`
  position: absolute;
  top: 0;

  width: 100vw;
  height: 100vh;

  background-color: ${theme.color.main};

  animation: ${slideIn} 0.4s linear;
`
const List = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`

const MenuLink = styled(NavLink)`
  margin: 20px 0;

  font-size: 2rem;

  &.active {
    color: ${theme.color.cta};
  }
`
