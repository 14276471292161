import styled from 'styled-components'

export const H1 = styled.h1`
  margin: 0;

  font-size: 4rem;
  letter-spacing: 2px;

  text-shadow: '0 1px 2px rgba(0,0,0,0.2)';
  text-transform: uppercase;
  text-align: center;
`
