import { useCheckMobileScreen } from '../../../hooks/useCheckMobileScreen'
import { MOBILE_BREAKPOINT } from '../../../const/breakpoint'
import { theme } from '../../../style/theme'
import { H3 } from '../../../typographie/H3'
import { H2 } from '../../../typographie/H2'
import { P } from '../../../typographie/P'

import Building from '../../../assets/images/common/building.jpeg'
import Hamac from '../../../assets/images/common/hamac.jpeg'
import Pool from '../../../assets/images/common/pool2.jpeg'

import styled from 'styled-components'
import { modalService } from 'services/modal'

export const Facilities = (): JSX.Element => {
  const isMobile = useCheckMobileScreen()

  const enlargePicture = (picture: string): void => {
    modalService.openModal()
    modalService.loadPicture(picture)
  }

  return (
    <FacilitiesContainer>
      <TextContainer>
        <H2>Nos Aménagements</H2>
        <Space></Space>
        <H3>Les Gîtes</H3>
        <P>La Casa de Jupas vous propose deux gîtes, avec les équipements suivants :</P>
        <ListContainer>
          <List>Terrasse privative avec jacuzzi et barbecue</List>
          <List>Cuisine équipée: Vaisselle, Four, Plaque Electrique, Cafétière...</List>
          <List>Climatisation</List>
          <List>Lave-vaisselle</List>
          <List>Lave-linge</List>
          <List>Sèche-cheveux</List>
          <List>TV</List>
        </ListContainer>

        <H3>La Piscine</H3>
        <P>Une piscine de 4,50m x 11m est diponible en commun à tous les gîtes</P>
        <P>Ouverture de 10h à 22h</P>
        <P>Des bouées, bains de soleil et parasols sont mis à disposition</P>
        <P>Les enfants doivent être absolument accompagnés d&apos;une personne majeure</P>

        <H3>Espace Détente</H3>
        <P>
          Un espace détente est à votre disposition, vous pouvez vous prélasser dans le hamac, jouer
          au Ping-Pong ou faire une partie de fléchettes
        </P>
      </TextContainer>
      <ImageContainer>
        {!isMobile && <Left src={Building} onClick={() => enlargePicture(Building)} />}
        <UpperRight src={Hamac} onClick={() => enlargePicture(Hamac)} />
        <LowerRight src={Pool} onClick={() => enlargePicture(Pool)} />
      </ImageContainer>
    </FacilitiesContainer>
  )
}

const FacilitiesContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: white;
`
const Space = styled.div`
  height: 10px;
`

const TextContainer = styled.div`
  text-align: center;
`

const ListContainer = styled.ul`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0;
  }
`

const List = styled.li`
  list-style-type: none;
  color: ${theme.text.main};
`

const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(2, 13rem);
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  width: 80%;
  margin: 6rem 0 0 0;
`

const Image = styled.img`
  cursor: pointer;
`

const Left = styled(Image)`
  grid-area: 1 / 1 / 3 / 2;
`

const UpperRight = styled(Image)`
  grid-area: 1 / 2 / 2 / 3;
`

const LowerRight = styled(Image)`
  grid-area: 2 / 2 / 3 / 3;
`
