import { useEffect, useState } from 'react'

import { useCheckTabletScreen } from '../../../../hooks/useCheckTabletScreen'
import { useCheckMobileScreen } from '../../../../hooks/useCheckMobileScreen'

import { NavigationArrow } from '../../../../components/common/NavigationArrow'
import { picturesCarouselService } from '../../../../services/picturesCarousel'
import { MOBILE_BREAKPOINT } from '../../../../const/breakpoint'
import { CarouselModal } from 'components/modal/CarouselModal'
import { modalService } from '../../../../services/modal'
import { theme } from '../../../../style/theme'
import { P } from '../../../../typographie/P'

import ArrowRight from '../../../../assets/icons/circle-arrow-right.png'
import ArrowLeft from '../../../../assets/icons/circle-arrow-left.png'

import styled from 'styled-components'

export const Carousel = (): JSX.Element => {
  const [picturesList, setIsPicturesList] = useState<string[]>([])
  const [selectedPicture, setSelectedPicture] = useState<string>('')

  const isTablet = useCheckTabletScreen()
  const isMobile = useCheckMobileScreen()

  // Define if displayed picture is first or last to hide arrows if it's the case
  const picturesListLength = picturesList.length - 1
  const isFirstPicture = picturesList.indexOf(selectedPicture) === 0
  const isLastPicture = picturesList.indexOf(selectedPicture) === picturesListLength

  // Load pictures, pictures list and picture index
  useEffect(() => {
    picturesCarouselService.loadPictures()

    const pictureSubscriber = picturesCarouselService._selectedPicture.subscribe((value) =>
      setSelectedPicture(value),
    )
    const listSubscriber = picturesCarouselService._picturesList.subscribe((value) =>
      setIsPicturesList(value),
    )

    // Unsubscribe to rxjs observables
    return () => {
      pictureSubscriber.unsubscribe()
      listSubscriber.unsubscribe()
    }
  }, [])

  const enlargePicture = (): void => {
    modalService.openModal()
    modalService.loadPicture()
  }

  return (
    <CarouselContainer>
      <PhotoContainer>
        {!isMobile && (
          <NavigationArrow
            style={isFirstPicture ? { visibility: 'hidden' } : {}}
            src={ArrowLeft}
            onClick={picturesCarouselService.previousPicture}
          />
        )}
        <Photo onClick={enlargePicture} src={selectedPicture} />
        {!isMobile && (
          <NavigationArrow
            style={isLastPicture ? { visibility: 'hidden' } : {}}
            src={ArrowRight}
            onClick={picturesCarouselService.nextPicture}
          />
        )}
      </PhotoContainer>
      {!isTablet && <Legend>Cliquez sur la photo pour agrandir</Legend>}
      {isMobile && <Legend>Cliquez sur la photo pour ouvrir la galerie</Legend>}
      <CarouselModal />
    </CarouselContainer>
  )
}

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 40vh;

  user-select: none;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 20px 0;
    height: auto;
  }
`

const PhotoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    justify-content: center;
  }
`

const Photo = styled.img`
  cursor: pointer;
  filter: saturate(1.2);

  max-width: 70%;
  width: auto;

  box-shadow: ${theme.boxShadow.secondary};
  border-radius: 5px;
`

const Legend = styled(P)`
  margin-top: 4px;

  text-align: center;

  font-size: 0.8rem;
  font-style: italic;
`
