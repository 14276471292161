interface INavBarLinks {
  path: string
  name: string
}

export const NavBarRoutes: INavBarLinks[] = [
  { path: '/', name: 'Accueil' },
  { path: '/gites', name: 'Gîtes' },
  { path: '/tarifs', name: 'Tarifs' },
  { path: '/contact', name: 'Contact' },
  // { path: '/faq', name: 'FAQ' },
]
