import { Description } from './description/Description'
import { Facilities } from './facilities/Facilities'
import { Hero } from './hero/Hero'
import { Layout } from '../../layout/Layout'
import { PictureModal } from 'components/modal/PictureModal'

export const Home = (): JSX.Element => {
  return (
    <Layout>
      <Hero />
      <Description />
      <Facilities />
      <PictureModal />
    </Layout>
  )
}
