export const theme = {
  color: {
    main: '#e6e6fa',
    cta: '#5CF72D',
    secondary: '#1414F7',
  },
  text: {
    title: 'black',
    main: '#686868',
  },
  boxShadow: {
    main: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
    secondary:
      'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
    hover: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
  },
  textShadow: {
    main: '3px 4px 7px rgba(81, 67, 21, 0.8)',
  },
}
