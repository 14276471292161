import { BehaviorSubject } from 'rxjs'
import { Gites, IGite } from '../models/Gites'
import { picturesCarouselService } from './picturesCarousel'

const gites: IGite[] = Gites
const _giteIndex$ = new BehaviorSubject<number>(0)
const _selectedGite$ = new BehaviorSubject<IGite>(gites[0])

export const gitesCarouselService = {
  _selectedGite: _selectedGite$.asObservable(),
  _giteIndex: _giteIndex$.asObservable(),

  nextGite() {
    const indexOfSelectedGite = gites.indexOf(_selectedGite$.value)
    _selectedGite$.next(gites[indexOfSelectedGite + 1])
    picturesCarouselService.resetIndex()

    picturesCarouselService.loadPictures()
  },

  previousGite() {
    const indexOfSelectedGite = gites.indexOf(_selectedGite$.value)
    _selectedGite$.next(gites[indexOfSelectedGite - 1])
    picturesCarouselService.resetIndex()

    picturesCarouselService.loadPictures()
  },
}
