import { Link } from 'react-router-dom'
import { theme } from '../../style/theme'

import { useNavBarLocation } from '../../hooks/useNavBarLocation'

import BlueNOBG from '../../assets/logos/blue-nobg.png'
import WhiteNOBG from '../../assets/logos/white-nobg.png'
import styled from 'styled-components'
import { useState } from 'react'
import { MobileMenu } from '../menu/MobileMenu'

export const MobileNavBar = (): JSX.Element => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const isTransparentNavBar = useNavBarLocation()

  const handleMenuOpening = (): void => {
    isMobileMenuOpen && setIsMobileMenuOpen(false)
    !isMobileMenuOpen && setIsMobileMenuOpen(true)
  }

  return (
    <NavBarContainer isTransparentNavBar={isTransparentNavBar}>
      <LogoContainer to='/'>
        <Logo src={isTransparentNavBar ? BlueNOBG : WhiteNOBG} />
      </LogoContainer>
      <Burger onClick={handleMenuOpening} isMobileMenuOpen={isMobileMenuOpen}>
        <Line />
        <Line />
        <Line />
      </Burger>
      {isMobileMenuOpen && <MobileMenu />}
    </NavBarContainer>
  )
}

const NavBarContainer = styled.div<{ isTransparentNavBar: boolean }>`
  display: flex;
  position: fixed;
  justify-content: space-between;
  align-items: center;

  height: 100px;
  width: 100vw;
  z-index: 2;
  background: linear-gradient(${theme.color.main} 40%, rgba(0, 0, 0, 0));

  ${({ isTransparentNavBar }) => isTransparentNavBar && 'background: none'};
`

const LogoContainer = styled(Link)`
  height: 70%;
  margin-left: 8%;
`

const Logo = styled.img`
  width: auto;
`
const Burger = styled.div<{ isMobileMenuOpen: boolean }>`
  z-index: 11;

  width: 20px;

  margin-right: 8%;

  span:nth-child(0n + 1) {
    ${({ isMobileMenuOpen }) => isMobileMenuOpen && { transform: 'rotate(-45deg)' }}
  }

  span:nth-child(0n + 2) {
    ${({ isMobileMenuOpen }) => isMobileMenuOpen && { visibility: 'hidden' }}
  }

  span:nth-child(0n + 3) {
    ${({ isMobileMenuOpen }) => isMobileMenuOpen && { transform: 'rotate(45deg)' }}
  }

  span {
    ${({ isMobileMenuOpen }) => isMobileMenuOpen && { margin: 0 }}
  }
`

const Line = styled.span`
  display: block;

  width: 30px;
  height: 1px;

  margin: 10px 0;

  background-color: black;

  transition: transform 0.4s ease-in-out;
`
