import styled from 'styled-components'

import { Body, Cell, HeaderCell, Line, Table } from '../../../../components/common/Table'
import { Legend } from '../../../../typographie/Legend'

export const Tarifs = (): JSX.Element => {
  return (
    <TarifsContainer>
      <Table>
        <Body>
          <Line>
            <HeaderCell>&nbsp;</HeaderCell>
            <HeaderCell>Hors-Saison*</HeaderCell>
            <HeaderCell>Saison*</HeaderCell>
          </Line>
          <Line>
            <HeaderCell>Les Pins</HeaderCell>
            <Cell>100€ / nuit</Cell>
            <Cell>800€ / semaine</Cell>
          </Line>
          <Line>
            <HeaderCell>Les Cèdres</HeaderCell>
            <Cell>100€ / nuit</Cell>
            <Cell>800€ / semaine</Cell>
          </Line>
          <Line>
            <HeaderCell>Caution</HeaderCell>
            <Cell colSpan={2}>500€</Cell>
          </Line>
          <Line>
            <HeaderCell>Taxe de séjour</HeaderCell>
            <Cell colSpan={2}>0.80€ / personne / nuit</Cell>
          </Line>
          <Line>
            <HeaderCell>Accompte</HeaderCell>
            <Cell colSpan={2}>30%</Cell>
          </Line>
        </Body>
      </Table>
      <Legend>
        * Saison : 1er Juin au 30 Septembre <br />* Hors-saison: 1er Octobre au 31 Mai
      </Legend>
    </TarifsContainer>
  )
}

const TarifsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
