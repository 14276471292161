export const Coordonnées: ICoordonnées = {
  streetNumber: 324,
  streetName: 'Chemin des Lauzières',
  zipCode: 30114,
  city: ' Nages et Solorgues',
  phoneNumber: '06.48.50.17.98.',
  mail: 'lacasadejupas@gmail.com',
}

interface ICoordonnées {
  streetNumber: number
  streetName: string
  zipCode: number
  city: string
  phoneNumber: string
  mail: string
}
