import { NavigationArrow } from '../../../components/common/NavigationArrow'
import { Services as ServicesView } from './services/Services'
import { Tarifs as TarifsView } from './tarifs/Tarifs'
import { useState } from 'react'

import LeftArrow from '../../../assets/icons/circle-arrow-left.png'
import RightArrow from '../../../assets/icons/circle-arrow-right.png'

import styled from 'styled-components'
import { H2 } from '../../../typographie/H2'

export const Tarifs = (): JSX.Element => {
  const [isView, setIsView] = useState<boolean>(true)

  const changeView = (): void => {
    setIsView(!isView)
  }

  return (
    <TarifsContainer>
      <TitleContainer>
        <NavigationArrow src={LeftArrow} onClick={changeView} />
        <H2>{isView ? 'Nos Tarifs' : 'Les Services'}</H2>
        <NavigationArrow src={RightArrow} onClick={changeView} />
      </TitleContainer>
      {isView ? <TarifsView /> : <ServicesView />}
    </TarifsContainer>
  )
}

const TarifsContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  margin-top: 50px;
`
