import { BehaviorSubject } from 'rxjs'
import { picturesCarouselService } from './picturesCarousel'

const _isModalShown$ = new BehaviorSubject<boolean>(false)
const _picture$ = new BehaviorSubject<string>('')

export const modalService = {
  _isModalShown: _isModalShown$.asObservable(),
  _picture: _picture$.asObservable(),

  openModal(): void {
    _isModalShown$.next(true)
  },

  closeModal(): void {
    _isModalShown$.next(false)
  },

  loadPicture(picture?: string): void {
    if (picture) _picture$.next(picture)
    else picturesCarouselService._selectedPicture.subscribe((value) => _picture$.next(value))
  },
}
