import { Body, Cell, HeaderCell, Line, Table } from '../../../../components/common/Table'

import styled from 'styled-components'

export const Services = (): JSX.Element => {
  return (
    <ServicesContainer id='services'>
      <Table>
        <Body>
          <Line>
            <HeaderCell>Ménage</HeaderCell>
            <Cell>50€ / séjour</Cell>
          </Line>

          <Line>
            <HeaderCell>Location de linge de maison(draps et serviettes)</HeaderCell>
            <Cell>15€ / séjour</Cell>
          </Line>
          <Line>
            <HeaderCell>Possibilité de réserver un massage</HeaderCell>
            <Cell colSpan={2}>A partir de 60€</Cell>
          </Line>
          <Line>
            <HeaderCell>Lit Parapluie &amp; Chaise Haute</HeaderCell>
            <Cell colSpan={2}>Gratuit</Cell>
          </Line>
        </Body>
      </Table>
    </ServicesContainer>
  )
}
const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
