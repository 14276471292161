import styled from 'styled-components'

import { useEffect, useState } from 'react'

import { GiteDescription } from './description/GiteDescription'
import { TABLET_BREAKPOINT } from '../../../const/breakpoint'
import { Gites, IGite } from '../../../models/Gites'
import { gitesCarouselService } from '../../../services/gitesCarousel'
import { Carousel } from './carousel/Carousel'
import { H2 } from '../../../typographie/H2'

import ChevronRight from '../../../assets/icons/chevron-right.png'
import ChevronLeft from '../../../assets/icons/chevron-left.png'

export const Gîte = (): JSX.Element => {
  const [selectedGite, setSelectedGite] = useState<IGite>(Gites[0])

  useEffect(() => {
    const subscriber = gitesCarouselService._selectedGite.subscribe((res) => setSelectedGite(res))

    return () => subscriber.unsubscribe()
  }, [])

  const isFirstGite = selectedGite?.index === 0
  const isLastGite = selectedGite?.index === Gites.length - 1

  return (
    <GîteContainer>
      <TitleContainer>
        <Arrow
          style={isFirstGite ? { visibility: 'hidden' } : {}}
          src={ChevronLeft}
          onClick={gitesCarouselService.previousGite}
        />
        <Arrow
          style={isFirstGite ? { visibility: 'hidden' } : {}}
          src={ChevronLeft}
          onClick={gitesCarouselService.previousGite}
        />
        <H2>{selectedGite?.name}</H2>
        <Arrow
          style={isLastGite ? { visibility: 'hidden' } : {}}
          src={ChevronRight}
          onClick={gitesCarouselService.nextGite}
        />
        <Arrow
          style={isLastGite ? { visibility: 'hidden' } : {}}
          src={ChevronRight}
          onClick={gitesCarouselService.nextGite}
        />
      </TitleContainer>
      <DownContainer>
        <Carousel />
        <GiteDescription gite={selectedGite} />
      </DownContainer>
    </GîteContainer>
  )
}

const GîteContainer = styled.section`
  display: flex;
  flex-direction: column;
`

const DownContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-around;
  align-items: center;
  flex: 1;

  margin-top: 2rem;

  @media (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
  }
`
const Arrow = styled.img`
  cursor: pointer;

  width: 30px;
  height: auto;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
