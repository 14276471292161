import { MOBILE_BREAKPOINT } from 'const/breakpoint'

import styled from 'styled-components'

export const NavigationArrow = styled.img`
  cursor: pointer;

  width: 40px;
  height: auto;

  &:hover {
    filter: drop-shadow(1px 1px 1px blue);
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 30px;
  }
`
