import Pins1 from '../assets/images/pins/bbq.jpeg'
import Pins2 from '../assets/images/pins/kitchen1.jpeg'
import Pins3 from '../assets/images/pins/bunk-beds1.jpeg'
import Pins4 from '../assets/images/pins/bedroom1.jpeg'
import Pins5 from '../assets/images/pins/bathroom.jpeg'

import Cedres1 from '../assets/images/cedres/outdoor1.jpeg'
import Cedres2 from '../assets/images/cedres/kitchen1.jpeg'
import Cedres3 from '../assets/images/cedres/bedroom2.jpeg'
import Cedres4 from '../assets/images/cedres/living-room1.jpeg'
import Cedres5 from '../assets/images/cedres/bathroom.jpeg'

import Climatisation from '../assets/icons/climatisation.png'
import Cuisine from '../assets/icons/kitchen.png'
import LaveLinge from '../assets/icons/lave-linge.png'
import LaveVaisselle from '../assets/icons/lave-vaisselle.png'
import Vaisselle from '../assets/icons/vaisselle.png'
import Jacuzzi from '../assets/icons/jacuzzi.png'
import TV from '../assets/icons/tv.png'

export const Gites: IGite[] = [
  {
    index: 0,
    name: 'Les Pins',
    photos: [Pins1, Pins2, Pins3, Pins4, Pins5],
    capacity: 5,
    superficy: 65,
    beds: 'Une chambre avec 1 lit double, une pièce avec 1 lit en mezzanine (1 place) et 1 canapé convertible (2 places)',
    equipements: [
      {
        name: 'Climatisation',
        icon: Climatisation,
      },
      {
        name: 'Cuisine équipée',
        icon: Cuisine,
      },
      {
        name: 'Lave Linge',
        icon: LaveLinge,
      },
      {
        name: 'Lave Vaisselle',
        icon: LaveVaisselle,
      },
      {
        name: 'Vaisselle',
        icon: Vaisselle,
      },
      {
        name: 'TV',
        icon: TV,
      },
      {
        name: 'Jacuzzi',
        icon: Jacuzzi,
      },
    ],
    bonus:
      'Mise à disposition d’un lit parapluie et d’une chaise haute sur demande et gratuitement (pour chaque gîte).',
  },
  {
    index: 1,
    name: 'Les Cèdres',
    photos: [Cedres1, Cedres2, Cedres3, Cedres4, Cedres5],
    capacity: 4,
    superficy: 55,
    beds: 'Une chambre avec 1 lit double et 1 salon avec un canapé convertible (2 places)',
    equipements: [
      {
        name: 'Climatisation',
        icon: Climatisation,
      },
      {
        name: 'Cuisine équipée',
        icon: Cuisine,
      },
      {
        name: 'Lave Linge',
        icon: LaveLinge,
      },
      {
        name: 'Lave Vaisselle',
        icon: LaveVaisselle,
      },
      {
        name: 'Vaisselle',
        icon: Vaisselle,
      },
      {
        name: 'TV',
        icon: TV,
      },
      {
        name: 'Jacuzzi',
        icon: Jacuzzi,
      },
    ],
    bonus:
      'Mise à disposition d’un lit parapluie et d’une chaise haute sur demande et gratuitement (pour chaque gîte).',
  },
]

export interface IGite {
  index: number
  name: string
  photos: string[]
  capacity: number
  superficy: number
  beds: string
  equipements: IEquipements[]
  bonus: string
}

interface IEquipements {
  name: string
  icon: string
}
