import { ArrowAnimation, StopAnimation } from '../../helpers/css.helper'
import { scrollToNextPage } from '../../helpers/scroll.helper'

import BlueArrow from '../../assets/icons/down-arrow.png'

import styled from 'styled-components'

export const ScrollDownArrow = ({ page }: { page: string }): JSX.Element => {
  return (
    <ScrollDownArrowContainer onClick={() => scrollToNextPage(page)}>
      <Arrow src={BlueArrow} />
      <Arrow src={BlueArrow} />
      <Arrow src={BlueArrow} />
    </ScrollDownArrowContainer>
  )
}

const ScrollDownArrowContainer = styled.div`
  position: absolute;
  bottom: 4%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 150px;
  height: 150px;

  img:nth-child(0n + 1) {
    animation-delay: -1s;
  }
  img:nth-child(0n + 2) {
    animation-delay: -0.5s;
  }
  img:nth-child(0n + 3) {
    animation-delay: 0s;
  }

  img {
    animation: ${ArrowAnimation} 2s infinite;
  }

  &:hover {
    cursor: pointer;
    div {
      animation: ${StopAnimation} 2s infinite;
    }
  }
`

const Arrow = styled.img`
  width: 80px;
  height: auto;
`
