import styled from 'styled-components'
import { TABLET_BREAKPOINT } from '../../../const/breakpoint'
import { Coordonnées } from '../../../models/Coordonnées'
import { H2 } from '../../../typographie/H2'
import { H3 } from '../../../typographie/H3'
import { P } from '../../../typographie/P'

export const Contact = (): JSX.Element => {
  return (
    <Container>
      <H2>Nous Contacter</H2>
      <InformationContainer>
        <H3>Coordonnées</H3>
        <P>
          <BlackText>Adresse : </BlackText>
          {Coordonnées.streetNumber} {Coordonnées.streetName}
        </P>
        <P>
          <BlackText>Code Postal : </BlackText>
          {Coordonnées.zipCode}
        </P>

        <P>
          <BlackText>Ville : </BlackText>
          {Coordonnées.city}
        </P>
        <P>
          <BlackText>Numéro de téléphone : </BlackText>
          {Coordonnées.phoneNumber}
        </P>
        <P>
          <BlackText>Mail : </BlackText>
          {Coordonnées.mail}
        </P>
        <H3>Accès</H3>
        <P>
          <BlackText>Gare TGV : </BlackText>
          Nîmes (20min en voiture) <br /> Montpellier Sud de France (35min en voiture)
        </P>
        <P>
          <BlackText>Aéroport : </BlackText>
          Nîmes Garons et Montpellier Méditerranée
        </P>
        <P>
          <BlackText>Autoroute : </BlackText>
          Arrivée par Lyon: A9 sortie Nîmes Ouest <br /> Arrivée par Perpignan/Toulouse: A9 sortie
          Gallargues
        </P>
      </InformationContainer>
      <MapContainer>
        <Map
          src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11519.860846988026!2d4.2401244!3d43.7943349!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe5a63725d6a1a6a6!2sLa%20Casa%20de%20Jupas!5e0!3m2!1sfr!2sfr!4v1659171571095!5m2!1sfr!2sfr'
          allowFullScreen={false}
          loading='lazy'
        ></Map>
      </MapContainer>
    </Container>
  )
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  padding: 100px 5% 5% 5%;
`

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 3%;

  text-align: center;
  P {
    margin: 7px 0;
  }
`

const MapContainer = styled.div`
  margin-top: 3%;

  width: 70%;
  @media (max-width: ${TABLET_BREAKPOINT}px) {
    width: 90%;
  }
`

const BlackText = styled.span`
  color: black;

  font-weight: bold;
`

const Map = styled.iframe`
  width: 100%;
  height: 60vh;
`
