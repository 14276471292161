import { Layout } from '../../layout/Layout'
import { Contact as ContactView } from './contact-us/Contact'

export const Contact = () => {
  return (
    <Layout>
      <ContactView />
    </Layout>
  )
}
