import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const useNavBarLocation = (): boolean => {
  // Find if user is on First Page

  const [isUpperPage, setIsUpperPage] = useState<boolean>(true)
  const getPosition = (): void => {
    const height = window.innerHeight
    const position = window.scrollY

    return setIsUpperPage(position < height)
  }

  useEffect(() => {
    window.addEventListener('scroll', getPosition)
    window.addEventListener('resize', getPosition)
    return () => {
      window.removeEventListener('scroll', getPosition)
      window.removeEventListener('resize', getPosition)
    }
  }, [])

  // Read path to know if user is on homepage
  const getLocation = (): boolean => {
    const location = useLocation()
    return location.pathname === '/' ? true : false
  }

  const isHomePage = getLocation()

  // Check if all conditions are good
  return isUpperPage && isHomePage
}
